
import api from "@/api";
import {
  BrandModel,
  KioskSessionErrorsLogCreateModel,
  ManualKioskErrorLogModel,
  SessionCreateModel,
} from "@/api/generated";
import Validation from "@/mixins/validation";
import SnackbarModule from "@/store/snackbarModule";
import { VForm } from "@/types/vForm";
import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";
import { component } from "vue/types/umd";
import { getModule } from "vuex-module-decorators";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class ManualErrorDialog extends Validation {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Prop(String) private kioskId!: string;
  @Ref() private readonly form!: VForm;
  private radioGroup = "";
  private model: ManualKioskErrorLogModel = {
    kioskId: "",
  };

  private async createManualError() {
    if (!this.form.validate()) {
      return;
    }
    try {
      this.model.kioskId = this.kioskId;
      this.model.state = this.radioGroup;
      await api.KioskService.apiKioskErrorsPost(this.model);
      snackbarModule.setSnackbarMessage("Successfully created Manual Error");
      this.$emit("created");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to create Manual Error");
    } finally {
      this.syncedDialog = false;
    }
  }
}
