
import { SessionModel } from "@/api/generated";
import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { DataTableHeader } from "vuetify";
import nameof from "@/utility/nameof";
import api from "@/api";
import SnackbarModule from "@/store/snackbarModule";
import UserModule from "@/store/userModule";
import userRoleConstant from "@/Constants/userRoleConstant";

const snackbarModule = getModule(SnackbarModule);
const userModule = getModule(UserModule);

@Component
export default class SessionTable extends Vue {
  @Prop(String) private deploymentId!: string;
  @Prop(Boolean) private deploymentClosed!: boolean;
  private loading = false;
  private items: Array<SessionModel> = [];

  private async created() {
    await this.getSessionsByDeploymentId();
  }

  private get adminPermissions() {
    return (
      userModule.userRole == userRoleConstant.systemAdministrator ||
      userModule.userRole == userRoleConstant.operationsManager
    );
  }

  private get adminOnsiteUserPermissions() {
    return userModule.userRole == userRoleConstant.onSiteUser;
  }

  get canEditOrDelete(): boolean {
    // Onsite user is view-only
    if (this.adminOnsiteUserPermissions) {
      return false;
    }

    return true;
  }

  private async getSessionsByDeploymentId() {
    try {
      this.loading = true;
      const response =
        await api.SessionService.apiSessionBydeploymentDeploymentIdGet(
          this.deploymentId
        );
      this.items = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retrieve sessions");
    } finally {
      this.loading = false;
    }
  }

  private showProfile(item: any) {
    this.$router.push({
      name: "SessionProfile",
      params: { sessionId: item.sessionId },
    });
  }
}
